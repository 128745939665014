import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import './css/index.css'

// Vue.config.productionTip = false

const app = createApp(App)
app.use(VueAxios, axios)
app.mount('#app')