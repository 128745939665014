<template>
  <div id="app" class="select-none">
    <img id="jesuit-logo" :src="blockJ" />
    <div id="day-order">
      <span class="font-sans">Day 4</span>
    </div>
    <div class="article-container">
      <div class="article-container-inner">
        <div v-if="haveArticles" class="full-article">
          <transition name="fade-slide" appear>
            <div class="full-article-content" v-if="showingArticle">
              <div class="full-article-inner flex justify-start items-end">
                <h2 class="article-title font-serif" v-html="currentArticle.title"></h2>
              </div>
            </div>
          </transition>
          <div class="full-article-background-tint"></div>
          <div class="full-article-shadow"></div>
          <transition name="fade" appear>
            <div class="full-article-background" :style="'background-image: url(' + currentArticle.featured_image_url + ')'" v-if="showingArticle"></div>
          </transition>
        </div>
      </div>
    </div>
    <div class="ticker-container">
    </div>
  </div>
</template>

<style>
  body {
    padding: 0;
    margin: 0;
    background-color: #000000;
  }

  #jesuit-logo {
    position: absolute;
    left: 5vw;
    top:  5vw;
    z-index: 20;
    width: 3.5vw;
    height: 3.5vw;
  }

  #day-order {
    position: absolute;
    right: 5vw;
    top: 5vw;
    z-index: 20;
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 3vw;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    height: 100vh;
    width: 100vw;
  }

  .article-container {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
  }

  .ticker-container {
    display: none;
    position: absolute;
    left: 0; right: 0; bottom: 0;
    height: 10vh;
    background-color: #002395;
  }

  .article-container-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .full-article {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
  }

  .full-article-content,
  .full-article-background,
  .full-article-background-tint {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
  }

  .full-article-content {
    z-index: 4;
    bottom: 5vw;
  }

  .full-article-shadow {
    z-index: 3;
    position: absolute;
    left: 0; right: 0; bottom: 0;
    height: 40vh;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.85));
  }

  .full-article-background-tint {
    z-index: 2;
    background-image: linear-gradient(to right, 
      rgba(35,35,35,.92), 
      rgba(90,90,90,.45), 
      rgba(35,35,35,.92)
    );
  }

  .full-article-background {
    z-index: 1;
    background-size: cover;
    background-position: 50% 50%;
  }

  .full-article-inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .full-article:not(:first-child) {
    display: none;
  }

  .article-thumb {
    width: 20vh;
    height: 20vh;
    background-size: cover;
    background-position: 50% 50%;
  }

  .article-title {
    color: #ffffff;
    padding: 0;
    margin: 0;
    font-size: 6vw;
    line-height: 1.125;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-slide-enter-active {
    transition: opacity 4s ease, transform 6s ease;
    transition-delay: .5s;
  }

  .fade-slide-leave-active {
    transition: opacity 1s ease, transform 4s ease;
  }

  .fade-slide-enter-from,
  .fade-slide-leave-to {
    opacity: 0;
    transform: translateY(3vh);
  }
</style>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    
  },
  data () {
    return {
      blockJ: require('./assets/block-j.svg'),
      articles: [],
      showingArticle: true,
      currentArticleIndex: 0,
      article_seconds: 12,
    }
  },
  computed: {
    haveArticles() {
      return this.articles.length
    },
    currentArticle() {
      return this.articles[this.currentArticleIndex]
    },
  },
  methods: {
    addArticle(post) {
      return this.articles.push(post)
    },
    gotoNextArticle() {
      let self = this
      const nextIndex = (this.articles.length > this.currentArticleIndex + 1) ? this.currentArticleIndex + 1 : 0

      // Go to the next article
      this.showingArticle = false
      window.setTimeout(() => {
        self.currentArticleIndex = nextIndex
        self.showingArticle = true
      }, (self.transition_seconds * 1000) + 1)
    },
    fetchArticles() {
      let self = this

      const categories = [
        8, /* News */
        71, /* Homepage */
      ]

      const base_url = 'https://www.jesuitnola.org'
      const endpoint = `${base_url}/wp-json/wp/v2/posts?categories=${categories.join(',')}`

      this.axios
        .get(endpoint)
        .then(({ data }) => {
          for (const post of data) {
            const preparedPost = {
              id: post.id,
              title: post.title.rendered,
              featured_image_url: post.featured_image_url.replace('www', 'cdn'),
            }
            self.addArticle(preparedPost)
          }
        })
    }
  },
  mounted () {

    this.fetchArticles()

    window.setInterval(() => {
      this.gotoNextArticle()
    }, this.article_seconds * 1000)

  },
}
</script>